import * as React from 'react'
import { ToolCard } from '../index'
import './styles.scss'

import { IconContext } from 'react-icons'
import { toolsData, icons } from '../../data'

export const ToolCards = props => {
  const styles = [
    {
      color: '#3bafb0',
      className: 'global-class-name',
      size: '3rem'
    },
    {
      color: '#c54f4f',
      className: 'global-class-name',
      size: '3rem'
    },
    {
      color: '#47b03b',
      className: 'global-class-name',
      size: '3rem'
    }
  ]
  // #3bafb0
  // #47b03b
  return (
    <div className='tool-cards'>
      <ul className='tool-cards-list'>
        {toolsData.map((tool, index) => {
          return (
            <ToolCard title={tool.title} description={tool.description}>
              <IconContext.Provider value={styles[index]}>
                {icons[index]}
              </IconContext.Provider>
            </ToolCard>
          )
        })}
      </ul>
    </div>
  )
}

export default ToolCards

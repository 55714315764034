import React, { useRef } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { AnimateSharedLayout } from 'framer-motion'
import {
  Header,
  Vanta,
  Section,
  ToolCards,
  ChatWidget,
  Video
} from './components'
import { Home, Work } from './pages'
import ReactGA from 'react-ga'
// import ReactImageVideoLightbox from 'react-image-video-lightbox'

// const [open, openLightbox] = useState(false)
//   {open && (
//     <ReactImageVideoLightbox
//       data={[
//         {
//           url: 'https://placekitten.com/450/300',
//           type: 'photo',
//           altTag: 'some image'
//         },
//         {
//           url: 'https://www.youtube.com/embed/ScMzIvxBSi4',
//           type: 'video',
//           title: 'some video'
//         },
//         {
//           url: 'https://placekitten.com/550/500',
//           type: 'photo',
//           altTag: 'some other image'
//         },
//         {
//           url: 'https://www.youtube.com/embed/ScMzIvxBSi4',
//           type: 'video',
//           title: 'some other video'
//         }
//       ]}
//       startIndex={0}
//       showResourceCount={true}
//       onCloseCallback={() => openLightbox(true)}
//       onNavigationCallback={currentIndex =>
//         console.log(`Current index: ${currentIndex}`)
//       }
//     />
//   )}

import { projectData, sectionData, footerData, projectIcons } from './data'

ReactGA.initialize('UA-225079088-1')
ReactGA.pageview(window.location.pathname)

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/work/:id', name: 'Work', Component: Work }
]

export default function App() {
  const dummy = useRef()

  return (
    <>
      <AnimateSharedLayout>
        <div className='app-wrapper'>
          <Router>
            <div className='inner-wrapper'>
              {routes.map(({ path, Component }) => (
                <Route key={path} exact path={path}>
                  {({ match }) => (
                    <CSSTransition
                      in={match != null}
                      timeout={700}
                      classNames='page'
                      unmountOnExit
                    >
                      <>
                        {Component === Home && (
                          <>
                            {/* <Vanta> */}
                            <Header routes={routes} projectsContainer={dummy} />
                            <Component match={match} />
                            {/* </Vanta> */}
                            <div className='sections'>
                              {sectionData.map((item, index) => {
                                return (
                                  <Section
                                    projectsContainer={
                                      index === 0 ? dummy : null
                                    }
                                    title={item.title}
                                    description={item.description}
                                    cards={
                                      item.title === 'Tools' ? (
                                        <ToolCards />
                                      ) : (
                                        ''
                                      )
                                    }
                                    footerData={
                                      item.title === 'Contact' && footerData
                                    }
                                    projectData={
                                      item.title === 'Projects' && projectData
                                    }
                                    projectIcons={projectIcons}
                                  />
                                )
                              })}
                            </div>
                          </>
                        )}
                        {Component !== Home && (
                          <>
                            <Header routes={routes} />
                            <Component match={match} />
                          </>
                        )}
                      </>
                    </CSSTransition>
                  )}
                </Route>
              ))}
              <ChatWidget />
            </div>
          </Router>
        </div>
      </AnimateSharedLayout>
    </>
  )
}

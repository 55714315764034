import { CgWebsite } from 'react-icons/cg'
import { FaShopify } from 'react-icons/fa'
import { FiDatabase } from 'react-icons/fi'
import { IoLogoNodejs } from 'react-icons/io'
import postgres from './assets/postgres.jpeg'

export const projectData = [
  {
    title: 'Hackmeister',
    description:
      'Hackmeister is a search app that allows fellow developers to search for coding debugging articles on the fly. The hope is that people have the opportunity to find answers to any blockers or bookmark articles they may want to revisit at a later time.',
    stack: 'React Native, Node.js',
    src:
      'https://camo.githubusercontent.com/ba6fbdbe30a5318f86889a3ce03354b0feafd3df8e4fed1a81c85a780bbf3682/68747470733a2f2f692e696d6775722e636f6d2f6b5666613550522e706e67',
    github: 'https://github.com/jkim3360/hackmeister'
  },
  {
    title: 'Spotify API/PostgreSQL Query',
    description:
      'CRUD with PostgreSQL querying a public Spotify API. Features include querying tracks by ISRC, adding rows to tracks and artists tables, creating reference keys to artists from tracks and sorting tracks based on popularity.',
    stack: 'PostgreSQL, Node.js',
    src: postgres,
    github: 'https://github.com/jkim3360/spotify-postgres'
  },
  {
    title: 'Shopify Quiz',
    description:
      'A Shopify product recommendation quiz built in Vue.JS that dynamically suggests items based on user responses. Shoppers can add the recommended product to cart or continue shopping at the end of the quiz for a continual and seamless shopping experience.',
    stack: 'Shopify, Vue.js, Node.js',
    src: 'https://assets-jkim3360.s3.us-east-2.amazonaws.com/caputo-quiz.mp4',
    video: true,
    github:
      'https://github.com/jkim3360/shopify-filter-quiz/tree/develop/src/modules/quiz'
  }
]

export const sectionData = [
  {
    title: 'Projects',
    description: ''
  },
  {
    title: 'Tools'
    // description: 'Tools description'
  },
  {
    title: 'Contact'
    // description: "Let's work together!"
  }
  // {
  //   title: 'About',
  //   description: 'Stuff I like to do.'
  // }
]

export const icons = [<CgWebsite />, <FiDatabase />, <FaShopify />]
export const projectIcons = [<IoLogoNodejs />]

export const toolsData = [
  {
    title: 'Front end',
    description: 'I have worked with React, HTML, CSS and Javascript '
  },
  {
    title: 'Back end',
    description: 'I have worked with Express, MongoDB, and PostgreSQL.'
  },
  {
    title: 'E-commerce',
    description:
      'I have developed Shopify stores to meet e-commerce needs for various clients.'
  }
]

export const footerData = [
  {
    title: 'Email',
    link: 'mailto: jamesdaehokim@gmail.com',
    linkValue: 'jamesdaehokim@gmail.com'
  },
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/in/james-daeho-kim/',
    linkValue: 'james-daeho-kim'
  },
  {
    title: 'Github',
    link: 'https://github.com/jkim3360',
    linkValue: 'jkim3360'
  }
]

export const items = [
  // Photo by ivan Torres on Unsplash
  {
    id: 'c',
    category: 'Pizza',
    title: '5 Food Apps Delivering the Best of Your City',
    pointOfInterest: 80,
    backgroundColor: '#814A0E',
    description: 'This is a description'
  },
  // Photo by Dennis Brendel on Unsplash
  // {
  //   id: 'f',
  //   category: 'How to',
  //   title: 'Arrange Your Apple Devices for the Gram',
  //   pointOfInterest: 120,
  //   backgroundColor: '#959684',
  //   description: 'This is a description',
  // },
  // Photo by Alessandra Caretto on Unsplash
  {
    id: 'a',
    category: 'Pedal Power',
    title: 'Map Apps for the Superior Mode of Transport',
    pointOfInterest: 260,
    backgroundColor: '#5DBCD2',
    description: 'This is a description'
  },
  // Photo by Taneli Lahtinen on Unsplash
  // {
  //   id: 'g',
  //   category: 'Holidays',
  //   title: 'Our Pick of Apps to Help You Escape From Apps',
  //   pointOfInterest: 200,
  //   backgroundColor: '#8F986D',
  //   description: 'This is a description',
  // },
  // Photo by Simone Hutsch on Unsplash
  {
    id: 'd',
    category: 'Photography',
    title: 'The Latest Ultra-Specific Photography Editing Apps',
    pointOfInterest: 150,
    backgroundColor: '#FA6779',
    description: 'This is a description'
  },
  // Photo by Siora Photography on Unsplash
  // {
  //   id: 'h',
  //   category: "They're all the same",
  //   title: '100 Cupcake Apps for the Cupcake Connoisseur',
  //   pointOfInterest: 60,
  //   backgroundColor: '#282F49',
  //   description: 'This is a description',
  // },
  // Photo by Yerlin Matu on Unsplash
  // {
  //   id: 'e',
  //   category: 'Cats',
  //   title: 'Yes, They Are Sociopaths',
  //   pointOfInterest: 200,
  //   backgroundColor: '#AC7441',
  //   description: 'This is a description',
  // },
  // Photo by Ali Abdul Rahman on Unsplash
  {
    id: 'b',
    category: 'Holidays',
    title: 'Seriously the Only Escape is the Stratosphere',
    pointOfInterest: 260,
    backgroundColor: '#CC555B',
    description: 'This is a description'
  }
]

export const openSpring = { type: 'spring', stiffness: 200, damping: 30 }

import React, { useState } from 'react'
import { randomize } from '../../services/helper'
import './styles.css'

var VANTAELEMENT = randomize()

export default class Vanta extends React.Component {
  constructor(props) {
    super()
    this.vantaRef = React.createRef()
  }
  componentDidMount() {
    this.vantaEffect = VANTAELEMENT({
      el: this.vantaRef.current,
      mouseControls: true,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      backgroundColor: 0x0,
      color: 0x40db33,
      color2: 0x4f17ef
    })
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy()
  }
  render() {
    return (
      <>
        <div className='vanta-wrapper' ref={this.vantaRef}>
          {this.props.children}
        </div>
      </>
    )
  }
}

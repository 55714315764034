import * as React from 'react'

import './styles.scss'

export const ToolCard = props => {
  return (
    <li className='tool-card'>
      {props.children}
      <h2>{props.title}</h2>
      <p className='p1'>{props.description}</p>
    </li>
  )
}

export default ToolCard

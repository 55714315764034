import RINGS from 'vanta/dist/vanta.rings.min'
import DOTS from 'vanta/dist/vanta.dots.min'
import BIRDS from 'vanta/dist/vanta.birds.min'
import GLOBE from 'vanta/dist/vanta.globe.min'
import TOPOLOGY from 'vanta/dist/vanta.topology.min'
import HALO from 'vanta/dist/vanta.halo.min'

export function randomize() {
  var VANTA
  const today = new Date().getDay()
  switch (today) {
    case 1:
      VANTA = RINGS
      break
    case 3:
      VANTA = HALO
      break
    case 5:
      VANTA = GLOBE
      break
    case 6:
      VANTA = DOTS
      break
    default:
      VANTA = BIRDS
      break
  }
  return VANTA
}

import React from 'react'
import Card from '../../components/Card'
import './styles.css'

const Work = () => {
  const data = [
    {
      body:
        '1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      body:
        '3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      body:
        '2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
  ]
  return (
    <div className="grid__cards">
      {data.map((elem, index) => {
        return (
          <Card
            classname={`grid__child-${index + 1}`}
            body={elem.body}
            value={index + 1}
          />
        )
      })}
    </div>
  )
}

export default Work

import React, { useState } from 'react'
// import TypeAnimation from 'react-type-animation'

import './styles.scss'

export const ChatWidget = props => {
  const [open, setOpen] = useState(false)
  console.log(document.getElementById('close-btn'), 'click event')

  window.addEventListener(
    'message',
    event => {
      if (event.data.dest !== 'daeho') return

      setOpen(event.data.false)
    },
    false
  )

  return (
    <div className={open ? 'chat-widget__wrapper' : ''}>
      <div
        className={'chat-widget' + (!open ? ' minimized' : '')}
        onClick={() => {
          setOpen(true)
        }}
      >
        {!open && (
          <span className='chat-widget__text'>
            {/* <TypeAnimation
              className={'chat-widget__text-animation'}
              cursor={true}
              sequence={['Click here to leave a message!', 2000]}
              wrapper='span'
            /> */}
            Chat with me
          </span>
        )}
        {open && (
          <iframe
            id='chat-id'
            src='
            https://practical-wright-f3a8df.netlify.app
            '
            // http://localhost:3001
            title='Super Chat'
            height='100%'
            width='100%'
            frameBorder='0'
          ></iframe>
        )}
      </div>
    </div>
  )
}

export default ChatWidget

import React from 'react'
import { ProjectCard, FooterCard } from '../index'
import './styles.scss'

export const Section = ({
  projectData,
  projectIcons,
  footerData,
  title,
  description,
  cards,
  projectsContainer
}) => {
  return (
    <div className='section container' ref={projectsContainer}>
      <h1>{title}</h1>
      <h2>{description}</h2>
      {cards && cards}
      <ul
        className={
          (projectData && 'project-card-list') ||
          (footerData && 'footer-card-list')
        }
      >
        {projectData &&
          projectData.map((projectData, index) => {
            console.log(projectData)
            return (
              <li className='project-card'>
                <ProjectCard
                  data={projectData}
                  projectIcons={projectIcons}
                  index={index}
                />
              </li>
            )
          })}
        {footerData &&
          footerData.map(footerData => {
            return (
              <li className='footer-card'>
                <FooterCard data={footerData} />
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default Section

import * as React from 'react'
import './styles.scss'
import { projectIcons } from '../../data'
import { IconContext } from 'react-icons'
import { FiGithub } from 'react-icons/fi'
import Video from '../Video'

export const ProjectCard = props => {
  const styles = [
    {
      color: '#3bafb0',
      className: 'global-class-name',
      size: '3rem'
    },
    {
      color: '#c54f4f',
      className: 'global-class-name',
      size: '3rem'
    },
    {
      color: '#47b03b',
      className: 'global-class-name',
      size: '3rem'
    }
  ]

  return (
    <>
      <div className='project-card-image-wrapper'>
        <a
          href={props.data.github && props.data.github}
          target='_blank'
          rel='noopener noreferrer'
        >
          {props.data.video ? (
            <Video src={props.data.src} />
          ) : (
            <img src={props.data.src} className='project-card-image' />
          )}
        </a>
      </div>
      <div className='project-card-subwrapper'>
        <h2 className='project-card-title'>{props.data.title}</h2>
        <p className='project-card-stack p2'>
          <span className='green'>Stack </span>
          {props.data.stack}
        </p>
        <p className='project-card-description'>{props.data.description}</p>
        <br />
        <a
          href={props.data.github && props.data.github}
          target='_blank'
          rel='noopener noreferrer'
        >
          {props.data.github && (
            <FiGithub color='#fff' style={{ fontSize: '25px' }} />
          )}
        </a>
      </div>
    </>
  )
}

export default ProjectCard

import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './styles.scss'

const Header = ({ routes, projectsContainer }) => {
  const [scrolled, setScrolled] = useState(false)

  window.addEventListener('scroll', () => {
    const isTop = window.scrollY < 87
    if (!isTop) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  })

  return (
    <header className={'header container ' + `${scrolled ? ' active' : ''}`}>
      <div className='navbar'>
        <div className='navbar-sub'>
          <Link
            key={'/'}
            as={NavLink}
            to={'/'}
            activeClassName='active'
            exact
            className={'navbar-title'}
          >
            <span className='logo'>dev</span>
          </Link>
        </div>
        <nav className='navbar-list'>
          {routes.map(route =>
            route.name.toLowerCase().includes('work') ? (
              <Link
                key={route.path}
                className={'nav-links navlink-contact'}
                onClick={() => {
                  projectsContainer.current.scrollIntoView({
                    behavior: 'smooth'
                  })
                }}
              >
                {route.name}
              </Link>
            ) : (
              <Link
                key={route.path}
                as={NavLink}
                to={route.path}
                activeClassName='active'
                exact
                className={'nav-links navlink-contact'}
              >
                {route.name}
              </Link>
            )
          )}
        </nav>
      </div>
    </header>
  )
}

export default Header

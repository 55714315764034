import React, { useState } from 'react'
import { AnimateSharedLayout, motion } from 'framer-motion'
import './styles.css'

const Card = ({ value, body, classname }) => {
  const [open, setOpen] = useState(false)
  const [questionFade, setFade] = useState(false)
  const variantsY = {
    show: { opacity: 1, y: 0, scale: 1 },
    hide: { opacity: 0, y: 200, scale: 0.5 },
  }
  return (
    <AnimateSharedLayout>
      <div
        style={{ color: 'white' }}
        onClick={() => {
          setFade(true)
        }}
      >
        CLICK ME Please
      </div>
      <motion.div
        initial={false}
        animate={questionFade === true ? 'show' : 'hide'}
        transition={{
          ease: 'easeOut',
          duration: 0.4,
          type: 'spring',
          stiffness: 260,
          damping: 25,
        }}
        onClick={() => {
          setFade(false)
        }}
        variants={variantsY}
      >
        <div className="chat-question shadow-sm mb-3">
          <div style={{ color: 'white' }}>Insert project content here</div>
        </div>
      </motion.div>
      {/* {open ? (
        <motion.div
          onClick={() => setOpen(false)}
          className={`expanded-card ${classname}`}
          layoutId="expandable-card"
          style={{ background: value }}
        >
          <motion.h2 className="expanded-card-h" layoutId="expandable-card-h">
            {body}
          </motion.h2>
        </motion.div>
      ) : (
        <motion.div
          onClick={() => setOpen(true)}
          className={
            open === true
              ? `normal-card normal-card--faded ${classname}`
              : `normal-card ${classname}`
          }
          layoutId="expandable-card"
          style={{ background: value }}
        >
          <motion.h1 layoutId="expandable-card-h">{value}</motion.h1>
        </motion.div>
      )} */}
    </AnimateSharedLayout>
  )
}

export default Card

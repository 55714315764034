import * as React from 'react'
import './styles.scss'

export const Video = props => {
  return (
    <video
      id='video'
      autoPlay
      muted='muted'
      loop='loop'
      playsinline='playsinline'
      preload='metadata'
      src={props.src}
    ></video>
  )
}

export default Video

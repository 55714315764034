import React from 'react'
import Video from '../../components/Video'
import backgroundImg from '../../assets/hero-code.jpeg'

const Home = () => {
  return (
    <div className='hero-container'>
      <div className='hero-image-container'>
        {/* <img src={backgroundImg} className='hero-image' /> */}
        <Video src='https://assets-jkim3360.s3.us-east-2.amazonaws.com/coding1.mp4' />
      </div>
      <div className='container hero-text-container'>
        <h1 className='hero-text hero-text--temp container'>
          Hi,
          <br />
          I'm James.
        </h1>
        <div className='hero-description hero-text--temp container'>
          Full Stack Developer
        </div>
      </div>
    </div>
  )
}

export default Home

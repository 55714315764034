import * as React from 'react'
import './styles.scss'

export const FooterCard = props => {
  return (
    <>
      <h2>{props.data.title}</h2>
      <a href={props.data.link} rel='noopener noreferrer' target='_blank'>
        {props.data.linkValue}
      </a>
    </>
  )
}

export default FooterCard
